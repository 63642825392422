import { StyledAsterisk } from './Asterisk.styled';
import { AsteriksProperties } from './Asterisk.types';

export function Asterisk({ spaceBefore, spaceAfter }: AsteriksProperties) {
    return (
        <StyledAsterisk data-testid="styled-asterisk">
            {spaceBefore && ' '}*{spaceAfter && ' '}
        </StyledAsterisk>
    );
}
