import styled from 'styled-components';

import { Theme, palette } from '../../theme';

export const StyledAsterisk = styled.span`
    color: ${palette.primary.main};
    font-size: 1.4rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;
