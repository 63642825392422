import styled from 'styled-components';

import { palette } from '../../theme';

export const StyledContainer = styled.div`
    padding: 2rem 1.5rem;
    text-align: center;
    background-color: ${palette.grey[100]};

    h1 {
        color: ${palette.common.blue};
    }
`;
